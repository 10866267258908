import Vue from 'vue';

const SiteHeader = () => import(/* webpackPrefetch: true, webpackChunkName: 'site-header' */ './components/SiteHeader');
const SiteLogo = () => import(/* webpackPrefetch: true, webpackChunkName: 'site-logo' */ './components/SiteLogo.vue');
const FooterLogo = () => import(/* webpackPrefetch: true, webpackChunkName: 'footer-logo' */ '../../svg/notary-logo--footer.svg');


new Vue({
    el: '#app',
    components: {
        SiteHeader, SiteLogo, FooterLogo
    }
})